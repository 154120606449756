import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import ContentWrapper from "styles/contentWrapper"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/category/hero"
import Clients from "components/sections/clients"
import Demos from "components/sections/category/demos"
import PrimaryCard from "components/common/primaryCard"
import Card from "components/common/card"
import Grid from "components/sections/grid"
import docImg from "content/images/search.svg"
import peopleImg from "content/images/people.svg"
import fileImg from "content/images/file.svg"
import CardNewsLetter from "components/common/cardNewsletter"
import Breadcumbs from "components/common/breadcumbs"
import jobAskingImg from "components/assets/job-asking.png"

const jobSearching = "https://hrflow-ai.imgix.net/labs/job-searching.png?w=350&h=350&fit=clip"
const jobScoring = "https://hrflow-ai.imgix.net/labs/talent-copilot.jpg?w=350&h=350&fit=clip"

const StyledFlex = styled.div`
  min-height: 19rem;
  display: flex;
  margin: auto;
  margin-top: 2rem;
  flex-wrap: wrap;
`

const JobIndexPage = ({ data }) => {
  const { frontmatter } = data.index.edges[0].node
  const { seoTitle, useSeoTitleSuffix, useSplashScreen } = frontmatter

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }
  console.log('frontmatter', frontmatter)
  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          frontmatter={frontmatter}
        />
        <Breadcumbs
          color="#2A7CF8"
          text="Back to demos"
          link="/"
        />
        <Hero
          content={data.hero.edges}
          logo={fileImg}
          color="#2A7CF8"
          docs="https://developers.hrflow.ai/reference/the-job-object"
        />
        <Grid>
          <PrimaryCard
            image={jobSearching}
            color="#2A7CF8"
            title="Job searching"
            link="/job-analysis/searching/"
            subtitle="Job Listing/Marketplace Search Demo powered by HrFlow.ai Job Searching API."
            codeUrl="https://codepen.io/HrFlow/pen/xxOeGwe"
          />
          <PrimaryCard
            image={jobScoring}
            color="#2A7CF8"
            title="Talent Copilot"
            link="/job-analysis/talent-copilot/"
            subtitle="Our AI-powered Talent Copilot is designed to help your candidates, employees, or job seekers identify the most qualified job opening for them."
          />
          <PrimaryCard
            image={jobAskingImg}
            color="#2A7CF8"
            title="Job asking"
            link="/job-analysis/asking/"
            subtitle="Ask a question based on a Job object with HrFlow.ai Job Asking API."
          />
          <CardNewsLetter 
            background="linear-gradient(284deg, #2A7CF8 0%, rgba(52, 137, 207, 0.8) 100%)"
            button="linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #458AF4"
          />
        </Grid>
        <Clients content={data.clients.edges} category />
        <Demos content={data.demos.edges}>
          <StyledFlex>
            <Card
              color="#22B5F3"
              icon={peopleImg}
              description="Dive in to interactive demos that derive insights from profiles, resumes and CVs."
              title="Profile Analysis"
              link="/profile-analysis/"
            />
            <Card
              color="#9F7AEA"
              icon={docImg}
              description="Dive in to interactive demos that derive insights from hr texts."
              title="Text Analysis"
              link="/text-analysis/"
            />
          </StyledFlex>
        </Demos>
      </Layout>
    </GlobalStateProvider>
  )
}

JobIndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default JobIndexPage

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/job/index/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            seoImage
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/job/index/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/home/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, quality: 90, placeholder: NONE)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    demos: allMdx(filter: { fileAbsolutePath: { regex: "/job/index/demos/" } }) {
      edges {
        node {
          frontmatter {
            label
            title
            titlePrefix
            description
          }
        }
      }
    }
  }
`
